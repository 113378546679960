<template>
  <div>
    <v-row
      justify="center"
      class="mt-4"
      v-if="
        template &&
        verein &&
        veranstaltung &&
        !blocked &&
        !login &&
        !error &&
        !gleicherverein &&
        !manuell
      "
    >
      <v-col cols="12" align-self="center" class="text-center">
        <p class="mb-0">{{ veranstaltung.art }}</p>
        <h2 class="white--text mt-0">
          {{ verein.name }} {{ veranstaltung.mannschaft }}
        </h2>
        <h4>
          gegen
        </h4>
        <h2>
          {{ veranstaltung.gastmannschaft }}
        </h2>
      </v-col>
      <v-col cols="12" class="text-center mb-0">
        <v-divider class="mb-4"></v-divider>
        <h2>GÄSTETEAMERFASSUNG</h2>
        <h3></h3>
        <h4 class="font-weight-bold text-uppercase">
          Bitte wähle aus, wie Du Dein Team erfassen möchtest:
        </h4>
      </v-col>
      <v-col cols="12" md="10" lg="8" class="mt-0">
        <v-card
          class="my-3 pa-2 rounded-xl"
          :color="template.colors.primary"
          :style="`color: ${template.colors.inline_primary_text};`"
          link
          v-if="!require_login"
          @click="anonym()"
          :loading="loading"
        >
          <v-row justify="center">
            <v-col cols="auto" class="ml-2" align-self="center">
              <v-icon large>mdi-card-account-details</v-icon>
            </v-col>

            <v-col align-self="center">
              <h3>
                Einmalige Erfassung
              </h3>
              <small>
                Die Daten deines Teams werden nur für dieses Spiel erfasst.
              </small>
            </v-col>
          </v-row>
        </v-card>

        <v-card
          class="my-3 pa-2 rounded-xl"
          color="success"
          link
          @click="zuschauerlogin = true"
        >
          <v-row justify="center">
            <v-col cols="auto" class="ml-2" align-self="center">
              <v-icon large>mdi-account-key</v-icon>
            </v-col>

            <v-col align-self="center">
              <h3>
                Login
              </h3>
              <small>
                Wenn du bereits einen Vereins-Account hast, dann kannst du dich
                einloggen und auf deine bereits erstellten Teams zugreifen.
              </small>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row
      justify="center"
      class="mt-4"
      v-if="
        verein &&
        veranstaltung &&
        !blocked &&
        !login &&
        !error &&
        !gleicherverein &&
        manuell
      "
    >
      <v-col
        cols="12"
        class="text-center"
        v-if="!veranstaltung.gastteam && step == 1"
      >
        <v-row justify="center">
          <v-col cols="12">
            <h2>
              <span class="font-weight-light">Schritt 1:</span>
              PIN-Code festlegen
            </h2>
            <p>
              Damit zukünftig keine unbefugten Personen Zugriff auf dein Team
              bekommen, lege bitte einen PIN-Code fest.
            </p>
          </v-col>
          <v-col cols="10" class="mt-4">
            <v-text-field
              label="PIN-Code"
              :hint="pin.length < 4 ? 'Min. 4 Zeichen' : undefined"
              :success="pin.length >= 4"
              :prepend-inner-icon="
                pin.length >= 4 ? 'mdi-lock' : 'mdi-lock-open-variant'
              "
              filled
              rounded
              :persistent-hint="pin.length < 4"
              v-model="pin"
            ></v-text-field>
            <v-btn
              large
              block
              rounded
              :color="template.colors.primary"
              :style="`color: ${template.colors.inline_primary_text};`"
              class="mt-5"
              @click="savepin()"
            >
              PIN SPEICHERN & WEITER
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <div>
        <v-col
          cols="12"
          class="text-center"
          v-if="veranstaltung.gastteam && step == 1"
        >
          <v-row justify="center" v-if="veranstaltung.gastteam.pin">
            <v-col cols="10" class="text-center">
              <h2>
                Verifizierung
              </h2>
              <p>
                Um dein Team zu bearbeiten, gebe bitte den PIN-Code ein.
              </p>
            </v-col>
            <v-col cols="10" class="mt-4">
              <v-text-field
                label="PIN-Code"
                :hint="pin.length < 4 ? 'Min. 4 Zeichen' : undefined"
                :success="pin.length >= 4"
                :prepend-inner-icon="
                  pin.length >= 4 ? 'mdi-lock' : 'mdi-lock-open-variant'
                "
                filled
                rounded
                :persistent-hint="pin.length < 4"
                v-model="pin"
              ></v-text-field>
              <v-btn
                large
                block
                rounded
                :color="template.colors.primary"
                :style="`color: ${template.colors.inline_primary_text};`"
                class="my-5"
                @click="check_pin()"
              >
                WEITER
              </v-btn>
              <v-alert prominent dense type="warning" v-if="pinfalse">
                Der eingegebene PIN-Code war nicht korrekt. Bitte versuche es
                erneut.
              </v-alert>
            </v-col>
          </v-row>
          <v-row justify="center" v-if="veranstaltung.gastteam.verein_id">
            <v-col cols="10">
              <h2>
                Verifizierung
              </h2>
              <p>
                Um dein Team zu bearbeiten, logge dich bitte ein.
              </p>
            </v-col>
          </v-row>
        </v-col>
      </div>
      <v-col cols="12" v-if="step == 2 && !finished">
        <v-row justify="center">
          <v-col cols="auto" class="text-center" align-self="center">
            <v-icon x-large>mdi-playlist-check</v-icon>
          </v-col>
          <v-col align-self="center">
            <h3 class="white--text font-weight-light">TEAM ANWESENHEIT</h3>
            <h2 class="white--text font-weight-bold">
              {{ veranstaltung.gastmannschaft }}
            </h2>
          </v-col>
        </v-row>
        <v-row justify="center" v-if="veranstaltung">
          <v-col
            cols="4"
            class="text-center"
            align-self="center"
            v-if="veranstaltung.beginn.datum != veranstaltung.ende.datum"
          >
            <h4 class="white--text font-weight-light">
              {{ parseDate(veranstaltung.beginn.datum) }}
              <br />
              <span class="font-weight-bold">
                {{ veranstaltung.beginn.uhrzeit }}
              </span>
            </h4>
          </v-col>
          <v-col
            cols="2"
            class="text-center"
            align-self="center"
            v-if="veranstaltung.beginn.datum != veranstaltung.ende.datum"
          >
            BIS
          </v-col>
          <v-col
            cols="4"
            class="text-center"
            align-self="center"
            v-if="veranstaltung.beginn.datum != veranstaltung.ende.datum"
          >
            <h4 class="white--text font-weight-light">
              {{ parseDate(veranstaltung.ende.datum) }}
              <br />
              <span class="font-weight-bold">
                {{ veranstaltung.ende.uhrzeit }}
              </span>
            </h4>
          </v-col>
          <v-col
            cols="10"
            class="text-center"
            align-self="center"
            v-if="veranstaltung.beginn.datum == veranstaltung.ende.datum"
          >
            <h4 class="white--text font-weight-light">
              Am
              <span class="font-weight-bold">
                {{ parseDate(veranstaltung.beginn.datum) }}
              </span>
              von
              <span class="font-weight-bold">
                {{ veranstaltung.beginn.uhrzeit }}
              </span>
              bis
              <span class="font-weight-bold">
                {{ veranstaltung.ende.uhrzeit }} Uhr
              </span>
            </h4>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="11" class="text-center">
            <h3 class="my-4 white--text" v-if="gastteam.length == 0">
              Für dieses Team wurden noch keine Mitglieder erfasst.
            </h3>
            <v-card
              v-for="(m, index) in gastteam"
              :key="index"
              class="my-1 rounded-xl"
              :color="delete_member_id == m.id ? 'error' : 'success'"
              @click="
                if (delete_member_id == m.id) {
                  delete_member = m
                  delete_tmember = true
                } else {
                  delete_member_id = m.id
                }
              "
            >
              <v-card-title>
                <v-icon class="mr-4" v-if="delete_member_id != m.id">
                  mdi-check-bold
                </v-icon>
                {{ m.person.vorname }} {{ m.person.name }}
                {{ delete_member_id == m.id ? ' löschen?' : '' }}
              </v-card-title>
              <v-card-subtitle>{{ m.aktivitaet }}</v-card-subtitle>
            </v-card>
            <v-dialog
              persistent
              v-model="delete_tmember"
              max-width="290"
              content-class="rounded-xl"
            >
              <v-card>
                <v-card-title class="headline font-weight-light">
                  Willst du&nbsp;
                  <div class="ml-2 font-weight-bold" v-if="delete_member">
                    {{ delete_member.person.vorname }}
                    {{ delete_member.person.name }}
                  </div>
                  wirklich löschen?
                </v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="error"
                    outlined
                    @click="
                      delete_tmember = false
                      delete_member_id = ''
                    "
                  >
                    Abbrechen
                  </v-btn>
                  <v-btn
                    color="success"
                    outlined
                    @click="del_member(delete_member)"
                  >
                    Bestätigen
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
          <v-col cols="10" class="mt-5 text-center">
            <v-btn rounded outlined block large @click="newmember = true">
              TEAMMITGLIED HINZUFÜGEN
            </v-btn>
            <CreateMember v-if="newmember" v-on:closing="newmember = false" />
          </v-col>
          <v-col cols="10" class="mt-5 text-center">
            <v-btn
              rounded
              block
              large
              @click="csvmember = true"
              color="primary"
            >
              <v-icon class="mr-1">mdi-database-import</v-icon>
              CSV IMPORT
            </v-btn>
            <TeamCSVImport v-if="csvmember" v-on:closing="csvmember = false" />
          </v-col>
          <v-col cols="10">
            <span
              class="font-weight-light white--text"
              v-if="
                template.var.verband == 'FLVW' || template.var.verband == 'FVM'
              "
            >
              Einverständniserklärung zur Erhebung personenbezogener Daten
              <br />
              <br />
              Der Verein ist im Rahmen der Coronaschutzverordnung (CoronaSchVO)
              des Landes Nordrhein-Westfalens (NRW) während der Corona-Pandemie
              zur Erhebung von personenbezogenen Kontaktdaten und
              Aufenthaltszeiträumen verpflichtet. Die Erhebung der Daten erfolgt
              gemäß § 4a Abs. 1 i.V.m. Abs. 2 CoronaSchVO NRW in der derzeit
              gültigen Fassung.
            </span>
            <span
              class="font-weight-light white--text"
              v-if="template.var.verband == 'BFV'"
            >
              Einverständniserklärung zur Erhebung personenbezogener Daten
              <br />
              <br />
              Der Verein ist im Rahmen der
              SARS-CoV-2-Infektionsschutzmaßnahmenverordnung des Landes Berlin
              während der Corona-Pandemie zur Erhebung von personenbezogenen
              Kontaktdaten und Aufenthaltszeiträumen verpflichtet. Die Erhebung
              der Daten erfolgt gemäß §4 Abs. 1-5
              SARS-CoV-2-Infektionsschutzmaßnahmenverordnung Berlin in der
              derzeit gültigen Fassung.
            </span>
            <v-checkbox v-model="accept_privacy">
              <template slot="label">
                <span>
                  Ich bestätige, dass ich von dem Inhaber der eingegeben Daten
                  zur Speicherung der Daten autorisiert wurde und diesem die
                  <a
                    :href="
                      template.datenschutz
                        ? template.datenschutz.link
                        : '/datenschutz'
                    "
                  >
                    Datenschutzinformation
                  </a>
                  zur Kenntnis gesetzt habe.
                </span>
              </template>
            </v-checkbox>
            <v-btn
              :color="template.colors.primary"
              :style="`color: ${template.colors.inline_primary_text};`"
              class="my-5"
              large
              rounded
              block
              :disabled="gastteam.length == 0 || !accept_privacy"
              :loading="saving"
              @click="finished = true"
            >
              BEARBEITUNG ABSCHLIESSEN
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="10" v-if="finished">
        <h2 class="text-center">Team erfolgreich erfasst!</h2>
        <h4 class="text-center">
          Vielen Dank für die Erfassung deines Teams für das Auswärtsspiel beim
          Verein {{ verein.name }}
        </h4>
      </v-col>
    </v-row>
    <v-row
      justify="center"
      class="mt-4"
      v-if="
        verein &&
        veranstaltung &&
        !blocked &&
        login &&
        !error &&
        !gleicherverein
      "
    >
      <v-col cols="12" md="10" lg="8">
        <v-card dark class="rounded-xl pa-0">
          <v-row justify="center" class="mt-0">
            <v-col cols="12" align-self="center" class="pt-0">
              <v-card
                class="rounded-t-xl"
                :color="template.colors.blocks"
                :style="`color: ${template.colors.block_text};`"
              >
                <v-row justify="center">
                  <v-col cols="12" class="text-center pb-0">
                    <h4>{{ veranstaltung.art }}</h4>
                  </v-col>
                  <v-col class="text-center" align-self="center">
                    <v-avatar tile><v-img :src="verein.logo"></v-img></v-avatar>
                    <p>{{ verein.name }}</p>
                  </v-col>
                  <v-col cols="auto" class="text-center" align-self="center">
                    <p>
                      {{ parseDate(veranstaltung.beginn.datum) }}
                      <br />
                      <span class="font-weight-bold">
                        {{ veranstaltung.beginn.uhrzeit }} Uhr
                      </span>
                    </p>
                  </v-col>
                  <v-col class="text-center" align-self="center">
                    <v-avatar tile>
                      <v-img :src="user.data.verein.logo"></v-img>
                    </v-avatar>
                    <p>{{ user.data.verein.name }}</p>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
          <v-expand-transition>
            <v-row justify="center">
              <v-col cols="12" class="pa-5">
                <h3 class="mb-4 text-center">
                  Bitte wähle das Team aus, das bei diesem Spiel zu Gast ist:
                </h3>
                <v-select
                  rounded
                  filled
                  :background-color="template.colors.primary"
                  :color="template.colors.inline_primary_text"
                  :style="`color: ${template.colors.inline_primary_text};`"
                  label="Team"
                  :items="teams"
                  v-model="team"
                ></v-select>
              </v-col>
              <v-col cols="12" class="pb-0">
                <cardbutton
                  :title="'Team auswählen'"
                  :icon="'mdi-check-bold'"
                  :textcolor="template.colors.inline_primary_text"
                  :bgcolor="template.colors.primary"
                  :disabled="!team"
                  :loading="loading"
                  :addclass="'rounded-b-xl mb-0'"
                  upper
                  @click="save_to_team()"
                />
              </v-col>
            </v-row>
          </v-expand-transition>
        </v-card>
      </v-col>
      <v-col cols="10" v-if="finished">
        <h2 class="text-center">Team erfolgreich erfasst!</h2>
        <h4 class="text-center">
          Vielen Dank für die Erfassung deines Teams für das Auswärtsspiel beim
          Verein {{ verein.name }}
        </h4>
      </v-col>
    </v-row>
    <v-row
      justify="center"
      class="mt-4"
      v-if="
        verein &&
        veranstaltung &&
        !blocked &&
        !login &&
        !error &&
        gleicherverein
      "
    >
      <v-col cols="12">
        <h3>Vorgang nicht möglich</h3>
        <h5>
          Es ist nicht möglich für dich das Gastteam zu bearbeiten, da du als
          Heimverein eingeloggt bist
        </h5>
      </v-col>
    </v-row>
    <v-row
      justify="center"
      class="mt-4"
      v-if="!veranstaltung && !blocked && !login && !error"
    >
      <v-col cols="12" class="text-center mb-4">
        <h2>Verein wird geladen</h2>
        <v-progress-circular
          :color="template.colors.primary"
          circle
          indeterminate
        ></v-progress-circular>
      </v-col>
    </v-row>
    <v-row justify="center" class="mt-4" v-if="blocked">
      <v-col cols="12" class="text-center mb-4">
        <h2>Da ist wohl etwas schief gelaufen...</h2>
        <p>
          Dein eingegebener Link scheint fehlerhaft zu sein. Bitte überprüfe
          deine Eingabe und versuche es erneut.
        </p>
      </v-col>
    </v-row>
    <v-row justify="center" class="my-5 pb-5">
      <v-col align-self="center">
        <v-divider class="my-3"></v-divider>
      </v-col>
      <v-col cols="auto">
        PROBLEME?
      </v-col>
      <v-col align-self="center">
        <v-divider class="my-3"></v-divider>
      </v-col>
      <v-col cols="10" class="text-center">
        <span>
          Dann hilft dir vielleicht unsere Anleitung weiter
        </span>
      </v-col>
      <v-col cols="10" class="mb-5">
        <v-btn
          block
          dark
          rounded
          large
          color="white"
          outlined
          :href="template.var.app_url + '' + template.var.anleitungen.gastteam"
          target="_blank"
        >
          ANLEITUNG ALS PDF
        </v-btn>
      </v-col>
    </v-row>
    <Login v-if="zuschauerlogin" v-on:closing="zuschauerlogin = false" />
    <CreateMember v-if="newmember" v-on:closing="newmember = false" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import store from '../../../store'
//import firebase from '../../../firebaseConfig'
import router from '../../../routes/index'
import Login from '../login.vue'
import CreateMember from './createMember'
import TeamCSVImport from './csvimport'
import Cardbutton from '../../../elements/cards/card-button'

export default {
  name: 'Gastteam-Erfassung',
  data() {
    return {
      tn_gast: [],
      teams: [],
      newzuschauer: false,
      zuschauerlogin: false,
      loading: false,
      gastparam: '',
      gleicherverein: false,
      accept_privacy: false,
      blocked: false,
      checkpin: true,
      login: false,
      error: false,
      manuell: false,
      errortext: '',
      pin: '',
      pinfalse: false,
      newmember: false,
      require_login: false,
      step: 1,
      team: '',
      teammember: [],
      tempmember: [],
      auswahl: [],
      finished: false,
      saving: false,
      csvmember: false,
      delete_member: '',
      delete_member_id: '',
      delete_tmember: false,
    }
  },
  computed: {
    ...mapGetters({
      veranstaltung: 'gastveranstaltung',
      verein: 'verein',
      gastteam: 'gastteam',
      template: 'template',
      user: 'user',
    }),
  },
  components: {
    Login,
    CreateMember,
    Cardbutton,
    TeamCSVImport,
  },
  mounted() {
    if (this.$route.params.gastparam && this.$route.params.verein) {
      this.gastparam = this.$route.params.gastparam.split('_')
      firebase
        .firestore()
        .collection('User')
        .where('alias', '==', this.$route.params.verein)
        .get()
        .then((vsnap) => {
          vsnap.forEach((vdoc) => {
            var verein = vdoc.data().verein
            verein.alias = vdoc.data().alias
            verein.id = vdoc.id
            verein.hygienekonzept = vdoc.data().hygienekonzept
            firebase
              .firestore()
              .collection('User')
              .doc(vdoc.id)
              .collection('Veranstaltung')
              .doc(this.gastparam[0])
              .get()
              .then((doc) => {
                if (doc.exists) {
                  var veranstaltung = doc.data()
                  veranstaltung.id = doc.id
                  store.dispatch('setGastteamseite', {
                    verein: verein,
                    veranstaltung: veranstaltung,
                  })
                  if (veranstaltung.gastpin != this.gastparam[1]) {
                    veranstaltung = ''
                    this.blocked = true
                  } else {
                    if (veranstaltung.gastteam) {
                      if (veranstaltung.gastteam.pin) {
                        this.checkpin = true
                        this.anonym()
                      } else if (veranstaltung.gastteam.verein_id) {
                        if (this.user.data) {
                          if (
                            this.user.data.uid ==
                            veranstaltung.gastteam.verein_id
                          ) {
                            this.login = true
                            store.dispatch('loadGastteam')
                            if (this.veranstaltung.gastverein) {
                              router.push(
                                '/veranstaltung/' +
                                  this.veranstaltung.gastverein.veranstaltung,
                              )
                            }
                          }
                        } else {
                          this.require_login = true
                        }
                      }
                    } else {
                      if (this.user) {
                        if (this.user.data) {
                          if (
                            this.user.data.rolle == 'Trainer' ||
                            this.user.data.rolle == 'Vorstand'
                            //&& this.user.data.uid != verein.id
                          ) {
                            this.login = true
                            store.dispatch('loadGastteam')
                          } else if (this.user.data.uid == verein.id) {
                            this.gleicherverein = true
                          }
                        }
                      }
                    }
                  }
                } else {
                  this.blocked = true
                }
              })
              .catch((error) => {
                this.error = true
                this.errortext = error
              })
          })
        })
        .catch((error) => {
          this.error = true
          this.errortext = error
        })
    } else {
      this.blocked = true
    }
    if (this.user.data) {
      if (this.user.data.team) {
        this.teams = this.user.data.team
      } else if (this.user.data.uid) {
        firebase
          .firestore()
          .collection('User')
          .doc(this.user.data.uid)
          .collection('Team')
          .get()
          .then((snap) => {
            this.teams = []
            snap.forEach((doc) => {
              this.teams.push(doc.id)
            })
          })
      }
    }
  },
  methods: {
    parseDate(date) {
      var datum = date.split('-')
      return datum[2] + '.' + datum[1] + '.' + datum[0]
    },
    save_to_team() {
      if (this.team) {
        this.loading = true
        firebase
          .firestore()
          .collection('User')
          .doc(this.user.data.uid)
          .collection('Veranstaltung')
          .add({
            art: 'Auswärtsspiel',
            beginn: this.veranstaltung.beginn,
            ende: this.veranstaltung.ende,
            gastmannschaft: this.veranstaltung.mannschaft,
            mannschaft: this.team,
            maxzuschauer: this.veranstaltung.maxzuschauer,
            sportstaette: this.veranstaltung.sportstaette,
            gastgeberverein: {
              id: this.verein.id,
              name: this.verein.name,
              logo: this.verein.logo,
              veranstaltung: this.veranstaltung.id,
            },
          })
          .then((docRef) => {
            firebase
              .firestore()
              .collection('User')
              .doc(this.verein.id)
              .collection('Veranstaltung')
              .doc(this.veranstaltung.id)
              .update({
                gastmannschaft: this.user.data.verein.name,
                gastverein: {
                  id: this.user.data.uid,
                  name: this.user.data.verein.name,
                  logo: this.user.data.verein.logo,
                  team: this.team,
                  veranstaltung: docRef.id,
                },
              })
              .then(() => {
                router.push('/veranstaltung/' + docRef.id)
              })
          })
      }
    },
    check_pin() {
      if (this.veranstaltung.gastteam.pin == this.pin) {
        this.step = 2
      } else {
        this.pinfalse = true
        setTimeout(() => {
          this.pinfalse = false
        }, 5000)
      }
    },
    abwesend(index) {
      var i
      for (i = 0; i < this.auswahl.length; i++) {
        if (this.teammember[index].id == this.auswahl[i].id) {
          this.auswahl.splice(i, 1)
          this.refresh_team()
        }
      }
    },
    anwesend(index) {
      this.auswahl.push(this.teammember[index])
      this.refresh_team()
    },
    refresh_team() {
      var member = this.teammember
      this.teammember = []
      var i, j, index
      for (i = 0; i < member.length; i++) {
        this.teammember.push(member[i])
        index = this.teammember.length - 1
        this.teammember[index].anwesend = false
        for (j = 0; j < this.auswahl.length; j++) {
          if (this.auswahl[j].id) {
            if (this.auswahl[j].id == this.teammember[index].id) {
              this.teammember[index].anwesend = true
            }
          }
        }
      }
    },
    load_gast_team() {
      var i, j, find
      for (i = 0; i < this.gastteam.length; i++) {
        find = false
        for (j = 0; j < this.teammember.length; j++) {
          if (this.gastteam[i].id) {
            if (this.gastteam[i].id == this.teammember[j].id) {
              find = true
            }
          }
          if (!find) {
            this.tempmember.push(this.gastteam[i])
          }
        }
      }
    },
    load_team(team) {
      this.team = team
      firebase
        .firestore()
        .collection('User')
        .doc(this.user.data.uid)
        .collection('Team')
        .doc(this.team)
        .collection('Mitglieder')
        .get()
        .then((snap) => {
          this.teammember = []
          this.auswahl = []
          snap.forEach((doc) => {
            this.teammember.push(doc.data())
            var i, index
            index = this.teammember.length - 1
            this.teammember[index].id = doc.id
            for (i = 0; i < this.gastteam.length; i++) {
              if (this.gastteam[i].id) {
                if (this.gastteam[i].id == this.teammember[index].id) {
                  this.teammember[index].anwesend = true
                  this.auswahl.push(this.teammember[index])
                }
              }
            }
          })
        })
    },
    async save_team() {
      this.saving = true
      var i, j
      for (i = 0; i < this.gastteam.length; i++) {
        var find = false
        for (j = 0; j < this.auswahl.length; j++) {
          if (this.gastteam[i].id) {
            if (this.gastteam[i].id == this.auswahl[j].id) {
              find = true
              j = this.gastteam.length
            }
          }
        }
        if (!find) {
          await firebase
            .firestore()
            .collection('User')
            .doc(this.verein.id)
            .collection('Veranstaltung')
            .doc(this.veranstaltung.id)
            .collection('Gastteam')
            .doc(this.gastteam[i].id)
            .delete()
            .catch((error) => {
              console.log(error)
            })
        }
      }

      for (i = 0; i < this.auswahl.length; i++) {
        await firebase
          .firestore()
          .collection('User')
          .doc(this.verein.id)
          .collection('Veranstaltung')
          .doc(this.veranstaltung.id)
          .collection('Gastteam')
          .doc(this.auswahl[i].id)
          .set(this.auswahl[i])
          .catch((error) => {
            console.log('Es ist ein Fehler aufgetreten!', error)
          })
      }
      await firebase
        .firestore()
        .collection('User')
        .doc(this.verein.id)
        .collection('Veranstaltung')
        .doc(this.veranstaltung.id)
        .update({
          gastteam: {
            verein_id: this.user.data.uid,
            team_id: this.team,
          },
        })
        .then(() => {
          this.saving = false
          this.step = 2
        })
      this.saving = false
      this.finished = true
    },
    savepin() {
      this.saving = true
      firebase
        .firestore()
        .collection('User')
        .doc(this.verein.id)
        .collection('Veranstaltung')
        .doc(this.veranstaltung.id)
        .update({
          gastteam: {
            pin: this.pin,
          },
        })
        .then(() => {
          this.saving = false
          this.step = 2
        })
    },
    del_member(member) {
      store.dispatch('removeGastteammember', member)
      this.delete_tmember = false
      ;(this.delete_member = ''), (this.delete_member_id = '')
    },
    anonym() {
      this.loading = true
      firebase
        .auth()
        .setPersistence(firebase.auth.Auth.Persistence.SESSION)
        .then(() => {
          // Existing and future Auth states are now persisted in the current
          // session only. Closing the window would clear any existing state even
          // if a user forgets to sign out.
          // ...
          // New sign-in will be persisted with session persistence.
          return firebase.auth().signInAnonymously()
        })
        .then(() => {
          this.loading = false
          this.manuell = true
          store.dispatch('loadGastteam')
        })
        .catch(function (error) {
          this.loading = false
          console.log(error)
        })
    },
  },
}
</script>
>
