<template>
  <v-dialog
    value="true"
    :fullscreen="$vuetify.breakpoint.mdAndDown"
    :persistent="$vuetify.breakpoint.lgAndUp"
    transition="dialog-bottom-transition"
    :max-width="$vuetify.breakpoint.lgAndUp ? '600' : undefined"
    dark
    style="padding-top: env(safe-area-inset-top);"
  >
    <v-card style="width: 100vw; overflow-x: hidden;" class="rounded-xl">
      <v-toolbar
        fixed
        :color="template.colors.primary"
        :style="`color: ${template.colors.inline_primary_text};`"
        style="
          padding-top: env(safe-area-inset-top);
          height: 80px + env(safe-area-inset-top);
        "
      >
        <v-toolbar-title>CSV IMPORT</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="closing()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-row justify="center" class="pt-0">
        <v-col cols="12" class="pt-0">
          <v-card
            :color="template.colors.blocks"
            class="rounded-b-xl pa-0"
            :style="`color: ${template.colors.block_text};`"
          >
            <v-row justify="center">
              <v-col cols="11" class="pb-0 pt-5" v-if="!csvfile.length > 0">
                <h3>Info:</h3>
                <p>
                  <b>
                    Baue deine CSV-Datei im UTF-8 Format auf und mit folgenden
                    Attributen (Trennzeichen: Semikolon (";")):
                  </b>
                </p>
                <code>
                  Nachname;Vorname;Straße;PLZ;Ort;Mobil;
                  <span
                    v-if="
                      verein
                        ? verein.hygienekonzept
                          ? verein.hygienekonzept.testpflicht
                          : false
                        : false
                    "
                  >
                    GGG
                  </span>
                  Nachname1;Vorname1;Straße1;PLZ1;Ort1;Mobil1;
                  <span
                    v-if="
                      verein
                        ? verein.hygienekonzept
                          ? verein.hygienekonzept.testpflicht
                          : false
                        : false
                    "
                  >
                    GGG
                  </span>
                  Nachname2;Vorname2;Straße2;PLZ2;Ort2;Mobil2;
                  <span
                    v-if="
                      verein
                        ? verein.hygienekonzept
                          ? verein.hygienekonzept.testpflicht
                          : false
                        : false
                    "
                  >
                    GGG
                  </span>
                  usw
                </code>
                <br />
                <small
                  v-if="
                    verein
                      ? verein.hygienekonzept
                        ? verein.hygienekonzept.testpflicht
                        : false
                      : false
                  "
                >
                  Hinweis: In die Spalte für "GGG" bitte "Getestet", "Genesen"
                  oder "Geimpft" schreiben
                </small>

                <v-divider class="my-2"></v-divider>
              </v-col>
              <v-col cols="11" class="pb-0 pt-5">
                <vue-csv-import
                  v-model="csvfile"
                  :map-fields="
                    verein
                      ? verein.hygienekonzept
                        ? verein.hygienekonzept.testpflicht
                          ? [
                              'Nachname',
                              'Vorname',
                              'Straße',
                              'PLZ',
                              'Ort',
                              'Mobil',
                              'GGG',
                            ]
                          : [
                              'Nachname',
                              'Vorname',
                              'Straße',
                              'PLZ',
                              'Ort',
                              'Mobil',
                            ]
                        : [
                            'Nachname',
                            'Vorname',
                            'Straße',
                            'PLZ',
                            'Ort',
                            'Mobil',
                          ]
                      : ['Nachname', 'Vorname', 'Straße', 'PLZ', 'Ort', 'Mobil']
                  "
                  tableClass="table border"
                  :headers="true"
                  :autoMatchFields="true"
                  v-if="!csvfile.length > 0"
                >
                  <template slot="error">
                    Dateityp ist ungültig
                  </template>

                  <template slot="thead">
                    <p>
                      Weise die Spalten aus deiner Datei den benötigten Feldern
                      zu
                    </p>
                    <tr>
                      <th style="border-bottom: 1px solid #000;">
                        Benötigte Felder
                      </th>
                      <th style="border-bottom: 1px solid #000;">
                        Spalten in deiner Datei
                      </th>
                    </tr>
                  </template>

                  <template slot="next" slot-scope="{ load }">
                    <v-btn
                      large
                      block
                      outlined
                      class="my-3"
                      :color="template.colors.primary"
                      @click.prevent="load"
                    >
                      DATEI LADEN
                    </v-btn>
                  </template>

                  <template slot="submit" slot-scope="{ submit }">
                    <v-btn
                      large
                      :color="template.colors.primary"
                      :style="`color: ${template.colors.inline_primary_text};`"
                      @click.prevent="submit"
                    >
                      IMPORT STARTEN
                    </v-btn>
                  </template>
                </vue-csv-import>
              </v-col>
              <v-col cols="11" class="pb-0 pt-5" v-if="csvfile.length > 0">
                <v-row>
                  <v-col cols="12">
                    <h2>Import-Vorschau</h2>
                  </v-col>
                  <v-col cols="12">
                    <v-row class="font-weight-bold">
                      <v-col>Name</v-col>
                      <v-col>Adresse</v-col>
                      <v-col>Telefon</v-col>
                      <v-col
                        v-if="
                          verein
                            ? verein.hygienekonzept
                              ? verein.hygienekonzept.testpflicht
                              : false
                            : false
                        "
                      >
                        3G-Regel
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" v-for="(row, index) in csvfile" :key="index">
                    <v-row>
                      <v-col>{{ row.Vorname }} {{ row.Nachname }}</v-col>
                      <v-col>
                        {{ row.Straße }}, {{ row.PLZ }} {{ row.Ort }}
                      </v-col>
                      <v-col>{{ row.Mobil }}</v-col>
                      <v-col
                        v-if="
                          verein
                            ? verein.hygienekonzept
                              ? verein.hygienekonzept.testpflicht
                              : false
                            : false
                        "
                      >
                        {{ row.GGG }}
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" class="text-center pb-0">
                <v-card
                  class="rounded-b-xl pa-4"
                  :color="template.colors.primary"
                  :style="`color: ${template.colors.inline_primary_text};`"
                  :disabled="loading || csvfile.length == 0"
                  @click="startImport()"
                >
                  <span class="font-weight-bold" v-if="!loading">
                    <v-icon class="mr-2">mdi-upload</v-icon>
                    TEAM IMPORTIEREN
                  </span>
                  <v-progress-circular
                    indeterminate
                    :color="template.colors.inline_primary_text"
                    v-if="loading"
                  ></v-progress-circular>
                </v-card>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
//import firebase from '../../../firebaseConfig'
import store from '../../../store'
import { mapGetters } from 'vuex'
import { VueCsvImport } from 'vue-csv-import'

export default {
  name: 'Spieler-erstellen',
  data() {
    return {
      csvfile: [],
      t: {
        vorname: '',
        name: '',
        adresse: '',
        plz: '',
        ort: '',
        telefon: '',
      },
      rules: {
        required: (value) => !!value || 'Bitte gebe deine Daten ein',
        name: (v) =>
          v.length >= 2 ? true : 'Bitte gebe einen gültigen Namen ein',
        street: (v) =>
          v.length >= 5 ? true : 'Bitte gebe einen gültigen Straßennamen ein',
        streetnr: (v) =>
          v.match(/[0-9]{1,}/)
            ? true
            : 'Bitte gebe eine gültige Hausnummer ein',
        telefon: (v) =>
          v.match(/[0-9]{5,}/)
            ? true
            : 'Bitte gebe eine gültige Telefonnummer ein',
        plz: (v) =>
          v.match(/[0-9]{5}/)
            ? true
            : 'Bitte gebe eine gültige Postleitzahl ein',
      },
      valid: false,
    }
  },
  mounted() {
    this.t.verein = this.verein.name
  },
  computed: {
    ...mapGetters({
      verein: 'verein',
      veranstaltung: 'gastveranstaltung',
      template: 'template',
    }),
  },
  components: { VueCsvImport },
  methods: {
    closing() {
      this.$emit('closing', true)
    },
    generateAnzeigename() {
      this.t.anzeigename = this.t.verein + ' ' + this.t.name
    },
    async startImport() {
      if (this.csvfile) {
        this.loading = true
        var event = this.veranstaltung
        for (let i = 0; i < this.csvfile.length; i++) {
          var ggg = ''
          if (this.csvfile[i].GGG) {
            var wert = this.csvfile[i].GGG.trim()
            if (wert == 'Getestet') {
              ggg = {
                getestet: true,
                geimpft: false,
                genesen: false,
              }
            }
            if (wert == 'Geimpft') {
              ggg = {
                getestet: false,
                geimpft: true,
                genesen: false,
              }
            }
            if (wert == 'Genesen') {
              ggg = {
                getestet: false,
                geimpft: false,
                genesen: true,
              }
            }
          }

          store.dispatch('addGastteammember', {
            person: {
              vorname: this.csvfile[i].Vorname.trim(),
              name: this.csvfile[i].Nachname.trim(),
              adresse: this.csvfile[i].Straße.trim(),
              ort: this.csvfile[i].Ort.trim(),
              plz: this.csvfile[i].PLZ.trim(),
              telefon: this.csvfile[i].Mobil.trim(),
            },
            ggg: ggg,
            veranstaltung: event,
            verein: {
              id: '0',
              name: event.gastmannschaft,
              team: ' ',
              gastverein: true,
            },
            aktivitaet: 'Sportler',
            sportstaette: {
              name: event.sportstaette,
            },
            zutritt: {
              datum: event.beginn.datum,
              uhrzeit: event.beginn.uhrzeit,
            },
            verlassen: {
              datum: event.ende.datum,
              uhrzeit: event.ende.uhrzeit,
            },
          })
        }
        this.closing()
      }
    },
  },
}
</script>
