var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{staticStyle:{"padding-top":"env(safe-area-inset-top)"},attrs:{"value":"true","fullscreen":_vm.$vuetify.breakpoint.mdAndDown,"persistent":_vm.$vuetify.breakpoint.lgAndUp,"transition":"dialog-bottom-transition","max-width":_vm.$vuetify.breakpoint.lgAndUp ? '600' : undefined,"dark":""}},[_c('v-card',{staticClass:"rounded-xl",staticStyle:{"width":"100vw","overflow-x":"hidden"}},[_c('v-toolbar',{staticStyle:{"padding-top":"env(safe-area-inset-top)","height":"80px + env(safe-area-inset-top)"},style:(("color: " + (_vm.template.colors.inline_primary_text) + ";")),attrs:{"fixed":"","color":_vm.template.colors.primary}},[_c('v-toolbar-title',[_vm._v("CSV IMPORT")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.closing()}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-row',{staticClass:"pt-0",attrs:{"justify":"center"}},[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('v-card',{staticClass:"rounded-b-xl pa-0",style:(("color: " + (_vm.template.colors.block_text) + ";")),attrs:{"color":_vm.template.colors.blocks}},[_c('v-row',{attrs:{"justify":"center"}},[(!_vm.csvfile.length > 0)?_c('v-col',{staticClass:"pb-0 pt-5",attrs:{"cols":"11"}},[_c('h3',[_vm._v("Info:")]),_c('p',[_c('b',[_vm._v(" Baue deine CSV-Datei im UTF-8 Format auf und mit folgenden Attributen (Trennzeichen: Semikolon (\";\")): ")])]),_c('code',[_vm._v(" Nachname;Vorname;Straße;PLZ;Ort;Mobil; "),(
                    _vm.verein
                      ? _vm.verein.hygienekonzept
                        ? _vm.verein.hygienekonzept.testpflicht
                        : false
                      : false
                  )?_c('span',[_vm._v(" GGG ")]):_vm._e(),_vm._v(" Nachname1;Vorname1;Straße1;PLZ1;Ort1;Mobil1; "),(
                    _vm.verein
                      ? _vm.verein.hygienekonzept
                        ? _vm.verein.hygienekonzept.testpflicht
                        : false
                      : false
                  )?_c('span',[_vm._v(" GGG ")]):_vm._e(),_vm._v(" Nachname2;Vorname2;Straße2;PLZ2;Ort2;Mobil2; "),(
                    _vm.verein
                      ? _vm.verein.hygienekonzept
                        ? _vm.verein.hygienekonzept.testpflicht
                        : false
                      : false
                  )?_c('span',[_vm._v(" GGG ")]):_vm._e(),_vm._v(" usw ")]),_c('br'),(
                  _vm.verein
                    ? _vm.verein.hygienekonzept
                      ? _vm.verein.hygienekonzept.testpflicht
                      : false
                    : false
                )?_c('small',[_vm._v(" Hinweis: In die Spalte für \"GGG\" bitte \"Getestet\", \"Genesen\" oder \"Geimpft\" schreiben ")]):_vm._e(),_c('v-divider',{staticClass:"my-2"})],1):_vm._e(),_c('v-col',{staticClass:"pb-0 pt-5",attrs:{"cols":"11"}},[(!_vm.csvfile.length > 0)?_c('vue-csv-import',{attrs:{"map-fields":_vm.verein
                    ? _vm.verein.hygienekonzept
                      ? _vm.verein.hygienekonzept.testpflicht
                        ? [
                            'Nachname',
                            'Vorname',
                            'Straße',
                            'PLZ',
                            'Ort',
                            'Mobil',
                            'GGG' ]
                        : [
                            'Nachname',
                            'Vorname',
                            'Straße',
                            'PLZ',
                            'Ort',
                            'Mobil' ]
                      : [
                          'Nachname',
                          'Vorname',
                          'Straße',
                          'PLZ',
                          'Ort',
                          'Mobil' ]
                    : ['Nachname', 'Vorname', 'Straße', 'PLZ', 'Ort', 'Mobil'],"tableClass":"table border","headers":true,"autoMatchFields":true},scopedSlots:_vm._u([{key:"next",fn:function(ref){
                    var load = ref.load;
return [_c('v-btn',{staticClass:"my-3",attrs:{"large":"","block":"","outlined":"","color":_vm.template.colors.primary},on:{"click":function($event){$event.preventDefault();return load($event)}}},[_vm._v(" DATEI LADEN ")])]}},{key:"submit",fn:function(ref){
                    var submit = ref.submit;
return [_c('v-btn',{style:(("color: " + (_vm.template.colors.inline_primary_text) + ";")),attrs:{"large":"","color":_vm.template.colors.primary},on:{"click":function($event){$event.preventDefault();return submit($event)}}},[_vm._v(" IMPORT STARTEN ")])]}}],null,false,1122649548),model:{value:(_vm.csvfile),callback:function ($$v) {_vm.csvfile=$$v},expression:"csvfile"}},[_c('template',{slot:"error"},[_vm._v(" Dateityp ist ungültig ")]),_c('template',{slot:"thead"},[_c('p',[_vm._v(" Weise die Spalten aus deiner Datei den benötigten Feldern zu ")]),_c('tr',[_c('th',{staticStyle:{"border-bottom":"1px solid #000"}},[_vm._v(" Benötigte Felder ")]),_c('th',{staticStyle:{"border-bottom":"1px solid #000"}},[_vm._v(" Spalten in deiner Datei ")])])])],2):_vm._e()],1),(_vm.csvfile.length > 0)?_c('v-col',{staticClass:"pb-0 pt-5",attrs:{"cols":"11"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h2',[_vm._v("Import-Vorschau")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{staticClass:"font-weight-bold"},[_c('v-col',[_vm._v("Name")]),_c('v-col',[_vm._v("Adresse")]),_c('v-col',[_vm._v("Telefon")]),(
                        _vm.verein
                          ? _vm.verein.hygienekonzept
                            ? _vm.verein.hygienekonzept.testpflicht
                            : false
                          : false
                      )?_c('v-col',[_vm._v(" 3G-Regel ")]):_vm._e()],1)],1),_vm._l((_vm.csvfile),function(row,index){return _c('v-col',{key:index,attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',[_vm._v(_vm._s(row.Vorname)+" "+_vm._s(row.Nachname))]),_c('v-col',[_vm._v(" "+_vm._s(row.Straße)+", "+_vm._s(row.PLZ)+" "+_vm._s(row.Ort)+" ")]),_c('v-col',[_vm._v(_vm._s(row.Mobil))]),(
                        _vm.verein
                          ? _vm.verein.hygienekonzept
                            ? _vm.verein.hygienekonzept.testpflicht
                            : false
                          : false
                      )?_c('v-col',[_vm._v(" "+_vm._s(row.GGG)+" ")]):_vm._e()],1)],1)})],2)],1):_vm._e(),_c('v-col',{staticClass:"text-center pb-0",attrs:{"cols":"12"}},[_c('v-card',{staticClass:"rounded-b-xl pa-4",style:(("color: " + (_vm.template.colors.inline_primary_text) + ";")),attrs:{"color":_vm.template.colors.primary,"disabled":_vm.loading || _vm.csvfile.length == 0},on:{"click":function($event){return _vm.startImport()}}},[(!_vm.loading)?_c('span',{staticClass:"font-weight-bold"},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-upload")]),_vm._v(" TEAM IMPORTIEREN ")],1):_vm._e(),(_vm.loading)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":_vm.template.colors.inline_primary_text}}):_vm._e()],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }